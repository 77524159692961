module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alejandra ToPa Psicóloga Cognitivo Conductual","short_name":"Alejandra ToPa","start_url":"/","background_color":"#FFF","theme_color":"#ef4136","display":"standalone","icon":"src/assets/images/icon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/@rhysforyou/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MGZF58Q"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
